@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }

    .App-main {
        width: 100%;
        height: 100vh;
        text-align: center;
        background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB)/*#282c34*/;
        background-size: 400% 400%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        animation: change 10s ease-in-out infinite;
    }

    .App-main-header {
        width: 80vw;
        border: 3px solid #fff;
        padding: 30px;
        font-size: 2rem;
    }

    .App-result{
        display: flex;
        flex-direction: column;
        margin: 30px 30px;
        padding: 30px 30px;
        background-color: #F7F8F9;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        -o-border-radius: 6px;
        -ms-border-radius: 6px;
        border-radius: 20px;
        color: #113C67;
        box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    }

    .App-result > p {
        margin-top: 5px;
        margin-bottom: 5px;
    }


    .App-link {
        color: #61dafb;
    }

    

    @keyframes change {
        0%{
            background-position: 0 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{
            background-position: 0 50%;
        }
    }


    .c_button{
        color:#fff;
	      background-color:#23D5AB;
	      border-radius:16px;
	      display:inline-block;
	      cursor:pointer;
	      font-size:20;
	      padding:14px 20px;
        margin: 5px;
        box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    }

    .c_button.loader {
        display:none;
    }

    .lds-circle {
        display: inline-block;
        transform: translateZ(1px);
    }
    .lds-circle > div {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 8px;
        border-radius: 50%;
        background: #fcf;
        animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    @keyframes lds-circle {
        0%, 100% {
            animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
        }
        0% {
            transform: rotateY(0deg);
        }
        50% {
            transform: rotateY(1800deg);
            animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
        }
        100% {
            transform: rotateY(3600deg);
        }
    }




    @keyframes fade-out{
        @-moz-keyframes spin {
            from { -moz-transform: opacity(1); }
            to { -moz-transform: opacity(0); }
        }
        @-webkit-keyframes spin {
            from { -webkit-transform: opacity(1);}
            to { -webkit-transform: opacity(0);}
        }
        @keyframes spin {
            from {transform:opacity(1);}
            to {transform:opacity(0);}
        }
    }



    @keyframes loading{
        @-moz-keyframes spin {
            from { -moz-transform: rotate(0deg); }
            to { -moz-transform: rotate(360deg); }
        }
        @-webkit-keyframes spin {
            from { -webkit-transform: rotate(0deg); }
            to { -webkit-transform: rotate(360deg); }
        }
        @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
        }
    }
    .c_button:hover {
    }
    .c_button:active {
	      position:relative;
	      top:1px;
    }
}
